import React from "react"
import { WithPrismicPreviewResolverProps, withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import { PageProps } from "gatsby"

const PreviewPage = ({
  isPreview,
  isLoading,
  isPrismicPreview,
  prismicPreviewPath,
  ...rest
}: {
  isPreview: boolean
  isLoading: boolean
} & PageProps
& WithPrismicPreviewResolverProps) => {
  
  // console.log(isPreview, isLoading, isPrismicPreview, prismicPreviewPath, rest)

  return (
    <div className="fixed inset-0 h-screen w-screen flex items-center justify-center">
      <h1 className="font-serif font-bold text-5xl">
        {isPreview ? `Loading...` : `No preview available.`}
      </h1>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage)